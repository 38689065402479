/*============================================================
    VARIABLES
============================================================*/
  var Window = $(window),                                               /* get window element */
      Document = $(document),                                           /* get document element */
      WindowWidth = Window.width(),                                     /* get window width */
      DocumentWidth = Document.width(),                                 /* get document width */
      WindowHeight = Window.height(),                                   /* get window height */
      DocumentHeight = Document.height()                                /* get document height */
      ;
/*============================================================
    FUNCTIONS
============================================================*/
  function exist(cssSelector) { return $(cssSelector).length > 0; }
  function toggleHeaderStyle() {
    $header = $('.navbar-default');
    $breakpoint = 1200;
    if ($(window).scrollTop() <= 0 && $(window).width() >= $breakpoint && $('.header-blank-space').length == 0){
  	  $header
        .removeClass("active");
  	}else{
      if ($(window).width() <= $breakpoint && $('.header-blank-space').length == 0) {
        $('.wrapper')
          .css({"margin-top":"85px"});
      }
  	  $header
        .addClass("active");
  	};
  }
  function fadeoutPreloader() {
    $('.slick-slider').on('init', function(event, slick){
      //$('.preloader').height($('.preloader').find('.slick-slide').height());
      $('.preloader').css({"overflow":"visible"});
      $('.preloader-status').fadeOut(1000);
    });
  }
  function enableSlider() {
    /* Check Element */
    var $singleItem = $(".single-item");
      if ($singleItem.length > 0) {
        $singleItem.each(function(i,el){
          if(!$(this).hasClass("slick-initialized")) {
            $(this).slick();
          }
        });
      }
  }
  function contact_maps_sm_height_cols() {
    if ($('body').width()>=768 && $('body').width()<992) {
      var imgHeight = "350px";
      $('.people.single,.people.triple')
        .find('.col-sm-6')
        .height(imgHeight);
    }
  }
  function masonry() {
    $('.masonry-container').masonry({
      columnWidth: '.item',
      itemSelector: '.item'
    });
  }
  function activefirstaccordion() {

    /* Check Element */
      if ($('.all-accordions').length > 0) {
        $('.collapse-wrapper')
          .addClass("active")
          .find('.collapse-element')
          .addClass("in");
      }else{
        $('.collapse-wrapper')
          .first()
          .addClass("active")
          .find('.collapse-element')
          .addClass("in");
      }
  }
  function recruitmentFormSizeFields() {
    $(".form-control").each(function(index){
      var labelElement = $(this).find('label'),
          inputElement = $(this).find("input, select, input[type='file'], .selectize"),
          borderWidth = 1,
          horizontalPadding = 10 + (borderWidth * 2),                                           /* horizontal padding is 12px (defined in css) */
          formControlWidth = $(this).width() - (horizontalPadding * 2),
          labelSize = labelElement.width(),
          restOfWidth = formControlWidth - labelSize;
      if ($(this).find("select").length > 0) {
        inputElement.width(restOfWidth - 20);                               /* remove counted background icon padding */
      }else{
        inputElement.width(restOfWidth);
      }
    });
  }
  function datepickers() {
    $('.datepicker-current-as-max').datepicker({
      maxDate: new Date(),
      dateFormat: "yy-mm-dd",
      changeYear: true,
      yearRange: "1900:+nn",
      changeMonth: true,
      //changeRange: "1900:+nn"
    }).prop("readonly","readonly");
  }
  function selectizes() {
    $('.selectize').selectize({
      plugins: ['remove_button'],
      create: false,
      sortField: 'name',
      valueField: 'value',
      mode: 'multi',
      labelField: 'name',
      searchField: ['name', 'value'],
    });
    if ($(window).width()>=992) {
      $('.selectize-simplified').selectize({
        create: false
      });
    }
  }
  function fixSpacePlaceholder() { // fix cms placeholder space :
    $('form input,form textarea').each(function(index,element) { // element == this 
      element.placeholder = element.placeholder.replace(" ","");
    });
  }
/*============================================================
    ACCORDION
============================================================*/

  function accordionsV2() {
    // Trigger Function :
      $('.collapse-wrapper .btn').click(function(e) {
        e.preventDefault();
        $(this).siblings('.collapse-element').toggle('collapse');
        $(this).parent().toggleClass("active");
      });
    // Start State :
      $('.accordion-button').each(function(index,element) { // element == this 
        if (!$(element).hasClass('open')) { // if has not class open
          $(this).find('.collapse-element').addClass('collapse');
        }
      });
  }

/*============================================================
    READY EVENTS
============================================================*/
  $(document).ready(function(){
    toggleHeaderStyle();
    accordionsV2();
    masonry();
    enableSlider();
    //activefirstaccordion();
    //recruitmentFormSizeFields();
    datepickers();
    selectizes();
    fixSpacePlaceholder();
    //contact_maps_sm_height_cols();
  });
/*============================================================
    RESIZE EVENT
============================================================*/
  $(window).resize(function(){ toggleHeaderStyle(); });
  /*============================================================
    SCROLL BUTTON STICKY LOGIC
  ============================================================*/
    var sz = $('.footer').height();
    if (exist('.scrollup')) { /* Check Element */
      $('.scrollup').hide();
      Window.scroll(function(){
        if (Window.scrollTop()>=100) {     $('.scrollup').fadeIn();    }
        else {                             $('.scrollup').fadeOut();   }
        if ( (Window.scrollTop() + Window.height()) >= Document.height()-sz) {
          $('.scrollup').addClass("sticky");
        } else {
          $('.scrollup').removeClass("sticky");
        }
      });
    }
/*============================================================
    SCROLL EVENT
============================================================*/
  $(document).scroll(function(){ toggleHeaderStyle(); });
/*============================================================
    OTHER EVENT
============================================================*/
  fadeoutPreloader();




// toggle product accordion badly made
var ff = false ;

function flipflop(){
  if (ff == true) { ff = false;
    $(".product-filter-close").html("-");
  }else if (ff == false){ ff = true;
    $(".product-filter-close").html("+");
  }
}

  $('#headingOne').click(function(){
    flipflop();
  });